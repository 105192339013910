import { useEffect } from "react";

import Image from "next/image";
import Link from "next/link";

import {Avatar, AvatarGroup} from "@nextui-org/react";

import "venobox/dist/venobox.min.css";

import bannerImg from "@/public/images/banner/banner-image-03.webp";
// import separator from "@/public/images/separator/separator-top.svg";
import Separator from "@/pages/separator";

import { NextSeo } from "next-seo";

const Home = () => {
    useEffect(() => {
        import("venobox/dist/venobox.min.js").then((venobox) => {
            new venobox.default({
                selector: ".popup-video",
                maxWidth: window.innerWidth >= 992 ? "50%" : "100%",
            });
        });
    }, []);

    return (
        <>
            <NextSeo
                title="Discover Powerful AI Tools for Content Creators"
                description="Cre8teGPT offers premium AI tools for content creators, including AI chat, image and video generators, article and lyrics generators, and various content assistants and agents to enhance your creative workflow."
                openGraph={{
                    type: "website",
                    url: "https://cre8tegpt.com",
                    title: "Cre8teGPT - AI Tools for Content Creators, Copywriters, Students, Influencers, and Businesses",
                    description:
                        "Cre8teGPT offers premium AI tools for content creators, including AI chat, image and video generators, article and lyrics generators, and various content assistants and agents to enhance your creative workflow.",
                    images: [
                        {
                            url: "https://cre8tegpt.com/logo/logo-big.png",
                            width: 800,
                            height: 600,
                            alt: "Cre8teGPT",
                        },
                    ],
                }}
            />
            
            <div className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1" data-black-overlay="1">
                {/* v2 class: rts-banner-area-one bg_image--1 bg_image rts-section-gap */}
                <div className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1">
                    <div className="container mt--50">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="content-main-wrapper">
                                    <h4 className="subtitle">
                                        <span className="theme-gradient">Try Cre8teGPT</span>
                                    </h4>

                                    <h1 className="title">
                                        100s of <span className="wrap">AI Tools</span>
                                        <br />
                                        1 <span className="wrap">Subscription</span>
                                        <br />
                                        $9.99
                                    </h1>

                                    <p className="disc">
                                        Your <strong>All-in-One AI-Powered Game Changer</strong> designed to empower content creators, copywriters, students, influencers and businesses of all sizes. <br /><strong>Discover 100+ AI Tools In 1 Place!</strong>
                                    </p>

                                    <div className="button-area">
                                        <Link href="/pricing" className="rts-btn btn-primary text-white">Try with 30 FREE cre8dits</Link>
                                    </div>

                                    <div className="user-area">
                                        <p>2,000+ Cre8tors saving 100s of hours</p>

                                        <AvatarGroup isBordered max={6} total={2000}>
                                            <Avatar color="default" src="https://i.pravatar.cc/150?u=a042581f4e29026024d" />
                                            <Avatar color="primary" src="https://i.pravatar.cc/150?u=a04258a2462d826712d" />
                                            <Avatar color="secondary" src="https://i.pravatar.cc/150?u=a042581f4e29026704d" />
                                            <Avatar color="success" src="https://i.pravatar.cc/150?u=a04258114e29026302d" />
                                            <Avatar color="warning" src="https://i.pravatar.cc/150?u=a04258114e29026702d" />
                                            <Avatar color="danger" src="https://i.pravatar.cc/150?u=a04258114e29026708c" />
                                        </AvatarGroup>

                                        {/* <div className="image-wrapper mb--10">
                                            <img className="one" src="/images/banner/user/01.png" alt="user" loading="lazy" />
                                            <img className="two" src="/images/banner/user/02.png" alt="user" loading="lazy" />
                                            <img className="three" src="/images/banner/user/04.png" alt="user" loading="lazy" />
                                            <img className="four" src="/images/banner/user/03.png" alt="user" loading="lazy" />
                                            <img className="five" src="/images/banner/user/05.png" alt="user" loading="lazy" />
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-lg-10 col-xl-10 order-1 order-lg-2">
                                <div className="frame-image frame-image-bottom bg-flashlight video-popup icon-center">
                                    <Image src={bannerImg} alt="Banner Images" />
                                    <div className="video-icon">
                                        <Link
                                            className="btn-default rounded-player popup-video border bg-white-dropshadow"
                                            href="https://youtu.be/ThRYF96HIzA?si=Yz-Yc5HSf8uLPv-G"
                                            data-vbtype="video"
                                        >
                                            <span>
                                                <i className="feather-play"></i>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    
                    <div className="home-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="icon-center">
                                        <div className="home-laptop px-md-0 px-3">
                                            <div className="laptop-sec position-relative">
                                                <div className="hand-sec">
                                                    <img src="/images/banner/hand.png" className="img-fluid left-hand" alt="hand" />
                                                    <img src="/images/banner/hand.png" className="img-fluid right-hand" alt="hand" />
                                                    <img src="/images/banner/finger.png" className="img-fluid left-finger" alt="hand" />
                                                    <img src="/images/banner/finger.png" className="img-fluid right-finger" alt="hand" />
                                                </div>
                                                
                                                <Image src="/images/banner/laptop.png" className="img-fluid laptop-img" alt="laptop" width={691} height={470} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="cre8tegpt-separator has-position-bottom">
                        <Image className="w-100" src={separator} alt="separator" />
                    </div> */}
                    <Separator top={true} extraClass="has-position-bottom" />

                    {/* <div className="bottom-mid-img">
                        <img src="/images/banner/01.png" alt="banenr mimage" loading="lazy" />
                    </div> */}

                    <div className="usefull-for-ani">
                        <div className="single influencer">
                            <Image src="/images/banner/user/influencer.png" alt="Cre8teGPT AI tools for influencers" width={42} height={42} />
                            <span>Influencers</span>
                        </div>

                        <div className="single creator">
                            <Image src="/images/banner/user/creator.png" alt="Cre8teGPT AI tools for content creators" width={42} height={42} />
                            <span>Content Creators</span>
                        </div>

                        <div className="single copywriter">
                            <Image src="/images/banner/user/copywriter.png" alt="Cre8teGPT AI tools for copywriters" width={42} height={42} />
                            <span>Copywriters</span>
                        </div>

                        <div className="single developer">
                            <Image src="/images/banner/user/developer.png" alt="Cre8teGPT AI tools for developers" width={42} height={42} />
                            <span>Developers</span>
                        </div>

                        <div className="single freelancer">
                            <Image src="/images/banner/user/freelancer.png" alt="Cre8teGPT AI tools for freelancers" width={42} height={42} />
                            <span>Freelancers</span>
                        </div>

                        <div className="single author">
                            <Image src="/images/banner/user/author.png" alt="Cre8teGPT AI tools for authors" width={42} height={42} />
                            <span>Authors</span>
                        </div>

                        <div className="single startup">
                            <Image src="/images/banner/user/startup.png" alt="Cre8teGPT AI tools for startups" width={42} height={42} />
                            <span>Startups</span>
                        </div>



                        <div className="single novelist">
                            <Image src="/images/banner/user/novelist.png" alt="Cre8teGPT AI tools for novelists" width={42} height={42} />
                            <span>Novelists</span>
                        </div>

                        <div className="single student">
                            <Image src="/images/banner/user/student.png" alt="Cre8teGPT AI tools for students" width={42} height={42} />
                            <span>Students</span>
                        </div>

                        <div className="single youtuber">
                            <Image src="/images/banner/user/youtuber.png" alt="Cre8teGPT AI tools for youtubers" width={42} height={42} />
                            <span>YouTubers</span>
                        </div>

                        <div className="single business">
                            <Image src="/images/banner/user/business.png" alt="Cre8teGPT AI tools for businesses" width={42} height={42} />
                            <span>Businesses</span>
                        </div>

                        <div className="single seo">
                            <Image src="/images/banner/user/seo.png" alt="Cre8teGPT AI tools for SEO experts" width={42} height={42} />
                            <span>SEO Experts</span>
                        </div>

                        <div className="single editor">
                            <Image src="/images/banner/user/editor.png" alt="Cre8teGPT AI tools for editors" width={42} height={42} />
                            <span>Editors</span>
                        </div>

                        <div className="single nonprofit">
                            <Image src="/images/banner/user/nonprofit.png" alt="Cre8teGPT AI tools for non-profits" width={42} height={42} />
                            <span>Non-Profit</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
