import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";

import { generators, editors, assistants } from "@/data/aitools";

import sal from "sal.js";
import toast from "react-hot-toast";

import createApiInstance from '@/services/api';

const AITools = () => {
    const api = createApiInstance();

    const [tools, setTools] = useState([]);

    useEffect(() => {
        sal();

        try {
            getTools();
        } catch (error) {
            console.error("Error fetching featured tools:", error);
        }
    }, []);

    const getTools = async () => {
        const response = await api.get(`${process.env.NEXT_PUBLIC_SERVER_URL}/api/tools/featured`);
        setTools(response.data.tools);
    }

    return (
        <>
            <div className="case-area-start index-two rts-section-gap">
                {/* <span className="bg-text">Featured AI Tools</span> */}
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="title-left-area" data-sal="slide-up" data-sal-duration="700" data-sal-delay="100">
                                <span className="pre-title-bg">Hundreds of AI Tools</span>
                                <h1 className="title">
                                    What You Get <br className="d-none d-md-block" />
                                    On Cre8teGPT
                                </h1>
                                <Link href="/tools">View All Tools <i className="fa-solid fa-arrow-right"></i></Link>
                            </div>
                        </div>

                        {tools.map((tool, index) => (
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={index} data-sal-duration="700" data-sal-delay="100">
                            <div className="single-case-area-wrapper">
                                {/* display only on desktop */}
                                <div className="icon d-none d-md-block">
                                    <img src={ tool.icon } alt={ tool.name } loading="lazy" width={50} height={50} />
                                </div>

                                {/* display only on mobile */}
                                <div className="screenshot mb--20 d-md-none">
                                    <img src={ `/images/screenshots/` + tool.slug + '.png' } alt={ tool.name } loading="lazy" />
                                </div>

                                <Link href={ tool.category_url }>
                                    <h5 className="title">{ tool.name }</h5>
                                </Link>
                                
                                <p className="disc">
                                    { tool.description }
                                </p>
                                
                                <Link href={ tool.category_url }>Try { tool.name } <i className="fa-solid fa-arrow-right"></i></Link>
                            </div>
                        </div>
                        ))}

                        <div className="button-group mt--50 text-center">
                            <Link className="btn-default btn-large" href="/tools"><i className="fa-solid fa-arrow-right"></i> Explore More AI Tools</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AITools;