import { getSession } from 'next-auth/react';
import { signOut } from 'next-auth/react';

import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

const createApiInstance = (token) => {
    const cache = axios.create({
        // baseURL: process.env.NEXT_PUBLIC_API_BASE_URL || 'https://api.cre8tegpt.com',
        // timeout: 10000,
        withCredentials: true,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
        }
    });

    const apiInstance = setupCache(cache);

    // Add a request interceptor
    apiInstance.interceptors.request.use(
        async (config) => {
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            const session = await getSession();
            if (session) {
                config.headers.Authorization = `Bearer ${session.accessToken}`;
            }
            return config;
        },
        (error) => {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    // Add a response interceptor
    apiInstance.interceptors.response.use(
        (response) => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                // Handle unauthorized errors, e.g., sign out the user
                const currentUrl = window.location.pathname;
                return signOut({ callbackUrl: '/auth/signin/?callbackUrl=' + currentUrl });
            }

            return Promise.reject(error);
        }
    );

    return apiInstance;
};

export default createApiInstance;

