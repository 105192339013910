import { useEffect } from "react";

import sal from "sal.js";

import Image from "next/image";
import Link from "next/link";

const Reviews = () => {
    const reviews = [
        {
            name: "Alex J.",
            title: "Startup Founder",
            avatar: "https://avatar.iran.liara.run/public/boy?username=alex",
            stars: 5,
            text: "Cre8teGPT has been a game-changer for our marketing team. We've been able to generate content at scale and at a fraction of the cost of what other AI platforms were charging. The quality of the content is amazing and the team is super responsive. Highly recommend!",
        },
        {
            name: "Sara M.",
            title: "Marketing Manager",
            avatar: "https://avatar.iran.liara.run/public/girl?username=sara",
            stars: 5,
            text: "I've been using Cre8teGPT for a few months now and it's been a huge time-saver. I used to spend hours  writing blog posts and social media content, but now it's easier than ever.",
        },
        {
            name: "Sarah L",
            title: "Influencer",
            avatar: "https://avatar.iran.liara.run/public/girl?username=sarah",
            stars: 4,
            text: "I can't say enough good things about Cre8teGPT. The Article Generator is a lifesaver for my blog. Saved me hours of work each week. Plus, all the YouTube specific tools just works so well."
        },
        {
            name: "Mark T.",
            title: "Copywriter",
            avatar: "https://avatar.iran.liara.run/public/boy?username=mark",
            stars: 5,
            text: "As a professional copywriter, I was skeptical about using AI tools. But Cre8teGPT's Writing Assistant has exceeded my expectations. It's my personal editor. I highly recommend it to anyone who writes for a living."
        },
        {
            name: "Emily R.",
            title: "YouTuber",
            avatar: "https://avatar.iran.liara.run/public/girl?username=emily",
            stars: 4,
            text: "Creating engaging thumbnails was always a struggle for me until I found Cre8teGPT. The YouTube Thumbnail Generator is fantastic. It’s quick, easy to use, and the results are eye-catching. My channel’s engagement has improved, and I have more time to focus on creating content.",
        },
        {
            name: "David P.",
            title: "Digital Marketer",
            avatar: "https://avatar.iran.liara.run/public/boy?username=david",
            stars: 4,
            text: "Cre8teGPT offers an amazing suite of tools for digital marketers. The quality of the generative AI is top-notch, and it’s so easy to use. Our campaigns have never looked better, and we’re seeing great results!",
        },
        {
            name: "Rachel S.",
            title: "Food Blogger",
            avatar: "https://avatar.iran.liara.run/public/girl?username=rachel",
            stars: 3,
            text: "I kinda feel like I'm cheating by using Cre8teGPT. The Recipe Generator is really good at coming up with literally any kind of recipe.",
        },
        {
            name: "Tom H.",
            title: "FreeLancer",
            avatar: "https://avatar.iran.liara.run/public/boy?username=tom",
            stars: 3,
            text: "Cre8teGPT has been a valuable addition to my freelance business. The variety of tools available is impressive. It's the best $9.99 I've spent in a long time.",
        },
        {
            name: "Linda C.",
            title: "Social Media Manager",
            avatar: "https://avatar.iran.liara.run/public/girl?username=linda",
            stars: 5,
            text: "I just love how all these tools are in a single platform. That's a huge time and money saver for me.",
        },
        {
            "name": "Jessica K.",
            "title": "Content Creator",
            "avatar": "https://avatar.iran.liara.run/public/girl?username=jessica",
            "stars": 5,
            "text": "Cre8teGPT's Image Generator is a lifesaver! Creating visuals for my blog and social media has never been easier. The quality is top-notch, and it saves me so much time. Definitely a must-have for any content creator."
        },
        {
            "name": "Michael W.",
            "title": "Entrepreneur",
            "avatar": "https://avatar.iran.liara.run/public/boy?username=michael",
            "stars": 4,
            "text": "As a startup founder, I need tools that are both efficient and affordable. Cre8teGPT delivers on both fronts. The Marketing Email Generator is fantastic and has boosted our campaign results significantly. Highly recommend it for any small business."
        },
        {
            "name": "Nina S.",
            "title": "Graphic Designer",
            "avatar": "https://avatar.iran.liara.run/public/girl?username=nina",
            "stars": 5,
            "text": "I was initially skeptical about using an AI tool for my design work, but Cre8teGPT's Image Generator has exceeded my expectations. The customization options are incredible, and it's become a valuable part of my toolkit."
        },
        {
            "name": "James B.",
            "title": "SEO Specialist",
            "avatar": "https://avatar.iran.liara.run/public/boy?username=james",
            "stars": 4,
            "text": "The XML Sitemap Generator has been a huge help for optimizing our website. It's simple to use and has made a noticeable difference in our SEO efforts. Cre8teGPT is an invaluable resource for anyone serious about improving their online presence."
        },
        {
            "name": "Olivia R.",
            "title": "Podcaster",
            "avatar": "https://avatar.iran.liara.run/public/girl?username=olivia",
            "stars": 5,
            "text": "Cre8teGPT’s Text to Speech tool is amazing for my podcast. I can easily convert scripts into natural-sounding audio. It's incredibly user-friendly and the quality is superb. Highly recommend it to fellow podcasters."
        },
        {
            "name": "Lucas M.",
            "title": "Social Media Influencer",
            "avatar": "https://avatar.iran.liara.run/public/boy?username=lucas",
            "stars": 5,
            "text": "The YouTube Channel Analyzer has been a game-changer for me. It provides in-depth insights that help me optimize my content strategy. Cre8teGPT has significantly boosted my channel’s performance and engagement."
        },
        {
            "name": "Megan T.",
            "title": "Freelance Writer",
            "avatar": "https://avatar.iran.liara.run/public/girl?username=megan",
            "stars": 4,
            "text": "The Article Generator is a fantastic tool for generating high-quality content quickly. It’s helped me meet tight deadlines without sacrificing quality. Cre8teGPT is a must-have for any freelance writer looking to streamline their workflow."
        },
        {
            "name": "Robert L.",
            "title": "E-commerce Store Owner",
            "avatar": "https://avatar.iran.liara.run/public/boy?username=robert",
            "stars": 4,
            "text": "I use Cre8teGPT’s Marketing Email Generator to craft personalized emails for my customers. The open rates have improved significantly, and the ROI is fantastic. It's an essential tool for any e-commerce business."
        },
        {
            "name": "Sophia P.",
            "title": "Travel Blogger",
            "avatar": "https://avatar.iran.liara.run/public/girl?username=sophia",
            "stars": 5,
            "text": "I love the variety of tools Cre8teGPT offers. The YouTube Summarizer has been especially useful for quickly extracting key points from travel videos. It saves me a ton of time and keeps my blog posts informative and engaging."
        },
        {
            "name": "Henry G.",
            "title": "Digital Content Strategist",
            "avatar": "https://avatar.iran.liara.run/public/boy?username=henry",
            "stars": 5,
            "text": "Cre8teGPT is a powerhouse of AI tools that every content strategist needs. From image creation to detailed content analysis, it's it em all."
        },
        {
            "name": "Charlie M.",
            "title": "Startup Entrepreneur",
            "avatar": "https://avatar.iran.liara.run/public/boy?username=charlie",
            "stars": 5,
            "text": "The Website Generator has been a revelation for my business. We were able to create a professional-looking site in just a few minutes. No need for coding or design skills. Cre8teGPT made the whole process incredibly smooth and efficient."
        },
        {
            "name": "Fiona B.",
            "title": "Travel Vlogger",
            "avatar": "https://avatar.iran.liara.run/public/girl?username=fiona",
            "stars": 4,
            "text": "The Video Generator tool is amazing for creating quick, engaging travel videos. It’s user-friendly and saves me so much editing time. I can focus more on capturing great footage rather than spending hours on post-production."
        },
        {
            "name": "Ethan K.",
            "title": "Fitness Coach",
            "avatar": "https://avatar.iran.liara.run/public/boy?username=ethan",
            "stars": 5,
            "text": "Cre8teGPT’s AI tools have transformed the way I create content for my fitness clients. The Ad Copy Generator helps me create compelling marketing campaigns that convert. It's an essential tool for any fitness professional looking to grow their business."
        },
        {
            "name": "Olivia H.",
            "title": "Fashion Blogger",
            "avatar": "https://avatar.iran.liara.run/public/girl?username=olivia",
            "stars": 4,
            "text": "The Writing Assistant is a game-changer for my blog posts. It ensures my content is polished and engaging, without any grammatical errors. Cre8teGPT has become an indispensable tool in my blogging toolkit."
        },
        {
            "name": "Sam R.",
            "title": "Graphic Artist",
            "avatar": "https://avatar.iran.liara.run/public/boy?username=sam",
            "stars": 5,
            "text": "As a graphic artist, I appreciate the flexibility and power of Cre8teGPT’s Image Generator. It allows me to create unique, high-quality visuals for my clients in no time. The creative possibilities are endless, and the results are always impressive."
        },
        {
            "name": "Lily A.",
            "title": "E-book Author",
            "avatar": "https://avatar.iran.liara.run/public/girl?username=lily",
            "stars": 5,
            "text": "The Article Generator is a fantastic tool for writing e-book content. It’s intuitive and produces high-quality, coherent text. Cre8teGPT has significantly reduced the time I spend on drafting and editing."
        },
        {
            "name": "Daniel P.",
            "title": "Tech Reviewer",
            "avatar": "https://avatar.iran.liara.run/public/boy?username=daniel",
            "stars": 4,
            "text": "The YouTube Summarizer tool is excellent for quickly summarizing tech reviews. It helps me stay on top of the latest gadgets and trends without spending hours watching videos. Cre8teGPT makes my work more efficient and effective."
        },
        {
            "name": "Laura G.",
            "title": "Educational Content Creator",
            "avatar": "https://avatar.iran.liara.run/public/girl?username=laura",
            "stars": 5,
            "text": "The Research Assistant has been a huge asset in my content creation process. It provides reliable and relevant information for my educational videos. Cre8teGPT has become my go-to tool for all my research needs."
        },
        {
            "name": "Chris T.",
            "title": "Digital Marketing Specialist",
            "avatar": "https://avatar.iran.liara.run/public/boy?username=chris",
            "stars": 4,
            "text": "The SEO Agent tool has significantly improved our website's search engine rankings. It provides detailed insights and recommendations that are easy to implement. Cre8teGPT is a must-have for any digital marketer looking to optimize their online presence."
        },
        {
            "name": "Mia W.",
            "title": "Lifestyle Influencer",
            "avatar": "https://avatar.iran.liara.run/public/girl?username=mia",
            "stars": 5,
            "text": "Cre8teGPT's suite of tools has made my content creation process so much smoother. The variety of tools available is impressive, and they are all incredibly user-friendly. My engagement rates have improved, and I have more time to focus on what I love."
        },
        {
            "name": "Jennifer K.",
            "title": "E-commerce Entrepreneur",
            "avatar": "https://avatar.iran.liara.run/public/girl?username=jennifer",
            "stars": 5,
            "text": "The Ad Copy Generator has revolutionized my e-commerce business. I can now produce captivating and effective ad copy in minutes, something that used to take me hours. Since using Cre8teGPT, my conversion rates have skyrocketed, and I’ve seen a significant increase in sales. It’s a must-have for any online store owner looking to boost their marketing efforts."
        }
    ];

    useEffect(() => {
        sal();
    }, []);

    return (
        <>
            <div className="rts-clients-review-area rts-section-gapTop">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="section-title text-center"
                                data-sal="slide-up"
                                data-sal-duration="700"
                                data-sal-delay="100"
                            >
                                <h4 className="subtitle">
                                    <span className="theme-gradient">Reviews</span>
                                </h4>
                                <h2 className="title w-600 mb--20">
                                    <span>2,000+</span> Cre8tors &amp; Teams <br />
                                    Choose Cre8teGPT
                                </h2>

                                <div className="brand-area">
                                    <img src="/images/01.png" alt="review 1" />
                                    <img src="/images/02_1.png" alt="review 2" />
                                    <img src="/images/03_1.png" alt="review 3" />
                                    <img src="/images/04_1.png" alt="review 4" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-tt mt--60">
                    <div className="marquee">
                        <div className="marquee__item">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="main--wrapper-tt">
                                        {reviews.slice(0, 6).map((review, index) => (
                                            <div className="single-testimonials-marquree" key={index}>
                                                <div className="top">
                                                    <div className="author">
                                                        <img src={review.avatar} alt={review.name + " review on Cre8teGPT"} />
                                                        <div className="info-content">
                                                            <h6 className="title">{review.name}</h6>
                                                            <span className="deg">{review.title}</span>
                                                        </div>
                                                    </div>

                                                    <div className="stars-area">
                                                        {[...Array(review.stars)].map((_, index) => (
                                                            <i key={index} className="fa-solid fa-star"></i>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className="body">
                                                    <p dangerouslySetInnerHTML={{ __html: review.text }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="marquee__item">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="main--wrapper-tt">
                                        {reviews.slice(6, 12).map((review, index) => (
                                            <div className="single-testimonials-marquree" key={index}>
                                                <div className="top">
                                                    <div className="author">
                                                        <img src={review.avatar} alt={review.name + " review on Cre8teGPT"} />
                                                        <div className="info-content">
                                                            <h6 className="title">{review.name}</h6>
                                                            <span className="deg">{review.title}</span>
                                                        </div>
                                                    </div>

                                                    <div className="stars-area">
                                                        {[...Array(review.stars)].map((_, index) => (
                                                            <i key={index} className="fa-solid fa-star"></i>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className="body">
                                                    <p dangerouslySetInnerHTML={{ __html: review.text }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="marquee mt--30">
                        <div className="marquee__item-2">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="main--wrapper-tt">
                                        {reviews.slice(12, 18).map((review, index) => (
                                            <div className="single-testimonials-marquree" key={index}>
                                                <div className="top">
                                                    <div className="author">
                                                        <img src={review.avatar} alt={review.name + " review on Cre8teGPT"} />
                                                        <div className="info-content">
                                                            <h6 className="title">{review.name}</h6>
                                                            <span className="deg">{review.title}</span>
                                                        </div>
                                                    </div>

                                                    <div className="stars-area">
                                                        {[...Array(review.stars)].map((_, index) => (
                                                            <i key={index} className="fa-solid fa-star"></i>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className="body">
                                                    <p dangerouslySetInnerHTML={{ __html: review.text }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="marquee__item-2">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="main--wrapper-tt">
                                        {reviews.slice(18, 24).map((review, index) => (
                                            <div className="single-testimonials-marquree" key={index}>
                                                <div className="top">
                                                    <div className="author">
                                                        <img src={review.avatar} alt={review.name + " review on Cre8teGPT"} />
                                                        <div className="info-content">
                                                            <h6 className="title">{review.name}</h6>
                                                            <span className="deg">{review.title}</span>
                                                        </div>
                                                    </div>

                                                    <div className="stars-area">
                                                        {[...Array(review.stars)].map((_, index) => (
                                                            <i key={index} className="fa-solid fa-star"></i>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className="body">
                                                    <p dangerouslySetInnerHTML={{ __html: review.text }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="marquee__item-2">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="main--wrapper-tt">
                                        {reviews.slice(24, 27).map((review, index) => (
                                            <div className="single-testimonials-marquree" key={index}>
                                                <div className="top">
                                                    <div className="author">
                                                        <img src={review.avatar} alt={review.name + " review on Cre8teGPT"} />
                                                        <div className="info-content">
                                                            <h6 className="title">{review.name}</h6>
                                                            <span className="deg">{review.title}</span>
                                                        </div>
                                                    </div>

                                                    <div className="stars-area">
                                                        {[...Array(review.stars)].map((_, index) => (
                                                            <i key={index} className="fa-solid fa-star"></i>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className="body">
                                                    <p dangerouslySetInnerHTML={{ __html: review.text }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Reviews;