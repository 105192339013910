import React, { useEffect } from "react";
import sal from "sal.js";

import Image from "next/image";
import Link from "next/link";

const Compare = () => {
    return (
        <>
            <section className="collaboration-one">
                <div className="container">
                    <div className="collaboration-one__inner">
                        <div className="collaboration-one__star-1 zoominout">
                            <img src="/images/shapes/collaboration-one-start-1.png" alt="collab 1" />
                        </div>

                        <div className="collaboration-one__star-2 float-bob-x">
                            <img src="/images/shapes/collaboration-one-start-2.png" alt="collab 2" />
                        </div>

                        <div className="collaboration-one__star-3 float-bob-y">
                            <img src="/images/shapes/collaboration-one-start-3.png" alt="collab 3" />
                        </div>

                        <div className="collaboration-one__star-4 zoominout">
                            <img src="/images/shapes/collaboration-one-start-4.png" alt="collab 4" />
                        </div>

                        <div className="collaboration-one__shape-1 float-bob-x" style={{ backgroundImage: "url(/images/shapes/collaboration-one-shape-1.png)" }}></div>
                        
                        <div className="collaboration-one__shape-2" style={{ backgroundImage: "url(/images/shapes/collaboration-one-shape-2.png)" }}></div>
                        
                        <div className="collaboration-one__title-box">
                            <p className="collaboration-one__sub-title">Integrations With</p>
                            <h3 className="collaboration-one__title">Streamlining Collaboration
                                <br /> with AIMug</h3>
                        </div>

                        <div className="collaboration-one__icon-box">
                            <div className="collaboration-one__icon">
                                <img src="/images/icons/cpu.png" alt="cpu icon" />
                                <div className="collaboration-one__icon-shape-1">
                                    <img src="/images/shapes/collaboration-one-icon-shape-1.png" alt="collab icon 1" />
                                </div>
                            </div>
                            <div className="collaboration-one__icon-1">
                                <img src="/images/compare/invideo.webp" alt="invideo icon" />
                            </div>
                            <div className="collaboration-one__icon-2">
                                <img src="/images/compare/subscribr.webp" alt="subscribr icon" />
                            </div>
                            <div className="collaboration-one__icon-3">
                                <img src="/images/compare/chatgpt.webp" alt="chatgpt icon" />
                            </div>
                            <div className="collaboration-one__icon-4">
                                <img src="/images/compare/jenni.jpg" alt="jenni icon" />
                            </div>
                            <div className="collaboration-one__icon-5">
                                <img src="/images/compare/byword.jpeg" alt="byword icon" />
                            </div>
                            <div className="collaboration-one__icon-6">
                                <img src="/images/icons/collaboration-6.png" alt="collab icon 6" />
                            </div>
                            <div className="collaboration-one__icon-7">
                                <img src="/images/icons/collaboration-7.png" alt="collab icon 7" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Compare;
