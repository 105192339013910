import { useEffect } from "react"

import Image from "next/image";
import sal from "sal.js";;

const Brands = () => {
    useEffect(() => {
        sal();
    }, []);

    return (
        <>
            <section className="brand-one d-none d-md-block">
                <div className="container">
                    <div className="brand-one__title-box">
                        <div className="brand-one__shape-1 zoominout">
                            <img src="/images/shapes/brand-one-shape-1.png" alt="brand 1" />
                        </div>

                        <div className="brand-one__shape-2 float-bob-x">
                            <img src="/images/shapes/brand-one-shape-2.png" alt="brand 2" />
                        </div>

                        <div className="brand-one__title count-box counted">Trusted by <span className="count-text" data-stop="10.8" data-speed="1500">5.5</span><span>k+</span> content cre8tors, teams, and companies.
                        </div>
                    </div>

                    <div className="brand-one__brand-list-box">
                        <div className="brand-one__shape-3 zoominout">
                            <img src="/images/shapes/brand-one-shape-3.png" alt="brand 3" />
                        </div>

                        <ul className="brand-one__list">
                            <li>
                                <div className="brand-one__brand-img">
                                    <img src="/images/brand/brand-logo-1.png" alt="brand 4" />
                                </div>
                            </li>
                            <li>
                                <div className="brand-one__brand-img">
                                    <img src="/images/brand/brand-logo-2.png" alt="brand 5" />
                                </div>
                            </li>
                            <li>
                                <div className="brand-one__brand-img">
                                    <img src="/images/brand/brand-logo-3.png" alt="brand 6" />
                                </div>
                            </li>
                            <li>
                                <div className="brand-one__brand-img">
                                    <img src="/images/brand/brand-logo-4.png" alt="brand 7" />
                                </div>
                            </li>
                            <li>
                                <div className="brand-one__brand-img">
                                    <img src="/images/brand/brand-logo-5.png" alt="brand 8" />
                                </div>
                            </li>
                            <li>
                                <div className="brand-one__brand-img">
                                    <img src="/images/brand/brand-logo-6.png" alt="brand 9" />
                                </div>
                            </li>
                        </ul>

                        <ul className="brand-one__list brand-one__list-2">
                            <li>
                                <div className="brand-one__brand-img">
                                    <img src="/images/brand/brand-logo-7.png" alt="brand 10" />
                                </div>
                            </li>
                            <li>
                                <div className="brand-one__brand-img">
                                    <img src="/images/brand/brand-logo-8.png" alt="brand 11" />
                                </div>
                            </li>
                            <li>
                                <div className="brand-one__brand-img">
                                    <img src="/images/brand/brand-logo-9.png" alt="brand 12" />
                                </div>
                            </li>
                            <li>
                                <div className="brand-one__brand-img">
                                    <img src="/images/brand/brand-logo-10.png" alt="brand 13" />
                                </div>
                            </li>
                            <li>
                                <div className="brand-one__brand-img">
                                    <img src="/images/brand/brand-logo-11.png" alt="brand 14" />
                                </div>
                            </li>
                            <li>
                                <div className="brand-one__brand-img">
                                    <img src="/images/brand/brand-logo-12.png" alt="brand 15" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Brands;
